import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { faFolder, faCamera } from '@fortawesome/free-solid-svg-icons';
import {Project} from '../../types/project';
import {ProjectsService} from '../../services/projects/projects.service';
import {ToastrService} from 'ngx-toastr';
import {FilesService} from '../../services/files/files.service';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss']
})
export class NewProjectComponent implements OnInit {

  @Output()
  projectCreated: EventEmitter<Project> = new EventEmitter<Project>();

  newProject: Project = new Project();

  constructor(private projectsService: ProjectsService,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.newProject.status = 'open';
  }

  addProject(): void {
    this.projectsService.addProject(this.newProject)
      .subscribe(project => {
        this.projectCreated.emit(project);
        this.newProject = new Project();
        this.toastrService.success('Project created successfully.', 'Success');
      });
  }
}
