import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Project} from '../../types/project';
import { faEllipsisV, faFileImport } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-previous-projects',
  templateUrl: './previous-projects.component.html',
  styleUrls: ['./previous-projects.component.scss']
})
export class PreviousProjectsComponent implements OnInit {
  faEllipsisV = faEllipsisV;
  faFileImport = faFileImport;

  @Output()
  projectSelected: EventEmitter<Project> = new EventEmitter<Project>();

  constructor() { }

  ngOnInit(): void {
  }

  onProjectSelected(project: Project): void {
    this.projectSelected.emit(project);
  }
}
