export class Project {
  id: number;
  customer: string;
  jobReference: string;
  address: string;
  contact: string;
  email: string;
  telephone: string;
  title: string;
  dateAttended: Date;
  jobDetails: string;
  supplierDetails: string;
  jobReattendedDetails: string;
  furtherWorks: string;
  jobInvoiced: boolean;
  invoiceNumber: string;
  invoicePaid: boolean;
  status: string;
}
