<div class="project-page">
  <app-header></app-header>
  <div class="grid-container full">
    <div class="grid-x">
      <div class="cell medium-10 medium-offset-1">
        <div class="grid-container full">
          <div class="grid-x grid-margin-x">
            <div class="cell medium-6">
              <app-new-project *ngIf="!selectedProject" (projectCreated)="projectCreated($event)"></app-new-project>
              <app-project-details *ngIf="selectedProject" [project]="selectedProject"></app-project-details>
              <app-project-status *ngIf="selectedProject" [project]="selectedProject"></app-project-status>
              <app-project-documents *ngIf="selectedProject"></app-project-documents>
            </div>
            <div class="cell medium-5 medium-offset-1">
              <app-previous-projects (projectSelected)="toggleProjectSelect($event)"></app-previous-projects>
              <app-project-list [projects]="projects" (projectSelected)="toggleProjectSelect($event)" [selectedProjectId]="selectedProjectId"></app-project-list>
              <div *ngIf="selectedProject">
                <app-project-financials [project]="selectedProject"></app-project-financials>
                <button class="button" (click)="saveProject()">Save</button><button class="button warning" *ngIf="showCloseProject" (click)="closeProject()">Close Project</button><button class="button success" *ngIf="!showCloseProject" (click)="openProject()">Open Project</button><button class="button" (click)="deleteProject()">Delete</button><button class="button muted" (click)="cancel()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

