<div>
  <div class="grid-container">
    <div class="grid-x">
      <div class="cell small-12">
        <app-project-report *ngFor="let project of projects$ | async" [project]="project"></app-project-report>
        <div class="controls">
          <a class="button" routerLink="/projects">Back to Projects</a>
        </div>
      </div>
    </div>
  </div>
</div>
