<div class="report">
  <h1>{{project.jobReference}} - {{project.customer}}</h1>
  <h2>{{ project.title }}</h2>
  <div class="section">
    <h3>Project Details</h3>
    <div>
      Contact: {{ project.contact }}<br />
      Address: {{ project.address }}<br />
      Date Attended: {{ project.dateAttended | date:'d MMM yyyy' }}
    </div>
  </div>
  <div class="section">
    <h3>Project Status</h3>
    <div>
      Job Details:<br />
      <p>{{ project.jobDetails }}</p>

      Supplier Details:<br />
      <p>{{ project.supplierDetails }}</p>

      Job Re-attended &amp; Details:<br />
      <p>{{ project.jobReattendedDetails }}</p>

      Further Works:<br />
      <p>{{ project.furtherWorks }}</p>
    </div>
  </div>
  <div class="section">
    <h3>Financial Information</h3>
    <div>
      Job Invoiced?: {{ project.jobInvoiced ? 'Yes' : 'No' }}<br />
      Invoice Number: {{ project.invoiceNumber }}<br />
      Invoice Paid?: {{ project.invoicePaid ? 'Yes' : 'No' }}
    </div>
  </div>
</div>
