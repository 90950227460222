<div class="project section">
  <h3>New Project</h3>
  <div class="grid-x grid-margin-x">
    <div class="cell medium-8">
      <input type="text" placeholder="Customer" [(ngModel)]="newProject.customer">
    </div>
    <div class="cell medium-4">
      <input type="text" placeholder="Job Reference" [(ngModel)]="newProject.jobReference">
    </div>

    <div class="cell medium-8">
      <input type="text" placeholder="Address" [(ngModel)]="newProject.address">
    </div>
    <div class="cell medium-4">
      <input type="text" placeholder="Contact" [(ngModel)]="newProject.contact">
    </div>

    <div class="cell medium-8">
      <input type="text" placeholder="Project Title" [(ngModel)]="newProject.title">
    </div>
    <div class="cell medium-4">
      <input type="date" placeholder="Date Attended" [(ngModel)]="newProject.dateAttended">
    </div>

    <div class="cell medium-12">
      <button class="button" (click)="addProject()">Create</button>
    </div>
  </div>
</div>
