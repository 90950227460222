<div class="project section">
  <h3>Documents</h3>
  <div class="grid-x grid-margin-x">
    <div class="cell medium-6">
      <input class="input-file" type="file" name="drawingsinput" id="drawingsinput" multiple (change)="fileChange($event)">
      <label for="drawingsinput"><fa-icon [icon]="faFolder"></fa-icon> Select Project Drawings</label>
      <input class="input-file" type="file" name="photosinput" id="photosinput" multiple>
      <label for="photosinput"><fa-icon [icon]="faCamera"></fa-icon> Select Project Photos</label>
      <div *ngIf="uploadedFiles && uploadedFiles.length > 0">
        <button class="button" (click)="upload()">
          Upload Images
        </button>
      </div>
    </div>
  </div>
</div>


