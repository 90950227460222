import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoginPageComponent } from './pages/login-page/login-page.component';
import { ProjectsPageComponent } from './pages/projects-page/projects-page.component';
import { LoginComponent } from './components/login/login.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { LogoutComponent } from './components/logout/logout.component';
import { HeaderComponent } from './components/header/header.component';
import { NewProjectComponent } from './components/new-project/new-project.component';
import { ProjectDetailsComponent } from './components/project-details/project-details.component';
import { ProjectFinancialsComponent } from './components/project-financials/project-financials.component';
import { ProjectSearchComponent } from './components/project-search/project-search.component';
import { ProjectListComponent } from './components/project-list/project-list.component';
import { ProjectListItemComponent } from './components/project-list/project-list-item/project-list-item.component';
import { CollapsibleTextBoxComponent } from './components/collapsible-text-box/collapsible-text-box.component';
import { ConfirmationBoxComponent } from './components/confirmation-box/confirmation-box.component';
import { PreviousProjectsComponent } from './components/previous-projects/previous-projects.component';
import { ProjectStatusComponent } from './components/project-status/project-status.component';
import { FooterComponent } from './components/footer/footer.component';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ProjectDetailsPageComponent } from './pages/project-details-page/project-details-page.component';
import { InvoicedProjectsPageComponent } from './pages/invoiced-projects-page/invoiced-projects-page.component';
import { ProjectReportComponent } from './components/project-report/project-report.component';
import { ProjectDocumentsComponent } from './components/project-documents/project-documents.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    ProjectsPageComponent,
    LoginComponent,
    LogoutComponent,
    HeaderComponent,
    NewProjectComponent,
    ProjectDetailsComponent,
    ProjectFinancialsComponent,
    ProjectSearchComponent,
    ProjectListComponent,
    ProjectListItemComponent,
    CollapsibleTextBoxComponent,
    ConfirmationBoxComponent,
    PreviousProjectsComponent,
    ProjectStatusComponent,
    FooterComponent,
    ProjectDetailsPageComponent,
    InvoicedProjectsPageComponent,
    ProjectReportComponent,
    ProjectDocumentsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      progressBar: true
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
