import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Project} from '../../types/project';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  private projectsUrl = 'http://api.agands.co.uk/api/projects';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  };

  constructor(private http: HttpClient) { }

  getProjects(): Observable<Project[]> {
    return this.http.get<Project[]>(this.projectsUrl);
  }

  getProjectById(id: number): Observable<Project> {
    return this.http.get<Project>(`${this.projectsUrl}/${id}`);
  }

  deleteProject(id: number): Observable<Project> {
    return this.http.delete<Project>(`${this.projectsUrl}/${id}`);
  }

  getInvoicedProjects(): Observable<Project[]> {
    return this.http.get<Project[]>(this.projectsUrl + '/invoiced');
  }

  updateProject(project: Project): Observable<any> {
    return this.http.put(`${this.projectsUrl}/${project.id}`, project, this.httpOptions);
  }

  addProject(project: Project): Observable<Project> {
    return this.http.post<Project>(this.projectsUrl, project, this.httpOptions);
  }

  searchProjects(term: string): Observable<Project[]> {
    if (!term.trim()) {
      return of([]);
    }

    return this.http.get<Project[]>(`${this.projectsUrl}/?customer=${term}`).pipe(
      tap(x => x.length ?
        console.log(`found projects matching "${term}"`) :
        console.log(`no projects matching "${term}"`)));
  }
}
