import { Component, OnInit } from '@angular/core';
import { faCamera, faFolder } from '@fortawesome/free-solid-svg-icons';
import {FilesService} from '../../services/files/files.service';

@Component({
  selector: 'app-project-documents',
  templateUrl: './project-documents.component.html',
  styleUrls: ['./project-documents.component.scss']
})
export class ProjectDocumentsComponent implements OnInit {
  uploadedFiles: FileList;

  faFolder = faFolder;
  faCamera = faCamera;

  constructor(private filesService: FilesService) { }

  ngOnInit(): void {
  }

  fileChange(element): void  {
    this.uploadedFiles = element.target.files;
  }

  upload() {
    const formData = new FormData();

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.uploadedFiles.length; i++) {
      formData.append('uploads[]', this.uploadedFiles[i], this.uploadedFiles[i].name);
    }

    this.filesService.upload(formData)
      .subscribe(response => {
        this.uploadedFiles = new FileList();
      });
  }

}
